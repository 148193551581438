import { useRouter } from "next/router"
import Script from "next/script"
import { memo } from "react"

const AccessibilityScript = () => {
  const { pathname } = useRouter()
  if (pathname === "/contact-us-form") {
    return null
  }
  return (
    <>
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
                (function(d){var s = d.createElement("script");s.setAttribute("data-account", "xUrd6ba0LC");s.setAttribute("src", "https://cdn.userway.org/widget.js");(d.body || d.head).appendChild(s);})(document)
`,
        }}
      />
      <noscript>
        Please ensure Javascript is enabled for purposes of{" "}
        <a href="https://userway.org"> website accessibility</a>
      </noscript>
    </>
  )
}
export default memo(AccessibilityScript)
